<template>
  <div>
    <div class="mb-3 flex items-center justify-between border-b pb-3">
      <div>
        <h2 class="text-xl font-semibold">Thêm nghệ sĩ</h2>
      </div>
      <div class="">
        <router-link :to="{name: 'AdminArtistList'}"
                     class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
          Danh sách nghệ sĩ
        </router-link>
      </div>
    </div>
    <div class="lg:flex lg:space-x-10">
      <div class="lg:w-3/4">
        <div class="max-w-2xl m-auto shadow-md rounded-md bg-white mt-3">
          <div class="grid grid-cols-2 gap-3 lg:p-6 p-4">
            <div class="col-span-2">
              <label for="title" class="font-semibold">Nghệ danh (*)</label>
              <input v-model="artist.title" class="shadow-none with-border" id="title" type="text" autocomplete="off"
                     required>
            </div>

            <div class="col-span-2">
              <label for="content" class="font-semibold">Tiểu sử</label>
              <editor id="content" v-model:content="artist.content"/>
            </div>

            <div class="col-span-2">
              <label for="rank" class="font-semibold">Danh hiệu</label>
              <input v-model="artist.rank" class="shadow-none with-border" id="rank" type="text" autocomplete="off">
            </div>

            <div class="col-span-2">
              <label for="real_name" class="font-semibold">Tên thật</label>
              <input v-model="artist.real_name" class="shadow-none with-border" id="real_name" type="text"
                     autocomplete="off">
            </div>

            <div class="col-span-2">
              <div class="grid grid-cols-3 gap-3">
                <div>
                  <label for="dob" class="font-semibold">Ngày sinh</label>
                  <input v-model="artist.dob" class="shadow-none with-border" id="dob" type="text" autocomplete="off">
                </div>
                <div>
                  <label for="mob" class="font-semibold">Tháng sinh</label>
                  <input v-model="artist.mob" class="shadow-none with-border" id="mob" type="text" autocomplete="off">
                </div>
                <div>
                  <label for="yob" class="font-semibold">Năm sinh</label>
                  <input v-model="artist.yob" class="shadow-none with-border" id="yob" type="text" autocomplete="off">
                </div>
              </div>
            </div>

            <div class="col-span-2">
              <div class="grid grid-cols-3 gap-3">
                <div>
                  <label for="dod" class="font-semibold">Ngày mất</label>
                  <input v-model="artist.dod" class="shadow-none with-border" id="dod" type="text" autocomplete="off">
                </div>
                <div>
                  <label for="mod" class="font-semibold">Tháng mất</label>
                  <input v-model="artist.mod" class="shadow-none with-border" id="mod" type="text" autocomplete="off">
                </div>
                <div>
                  <label for="yod" class="font-semibold">Năm mất</label>
                  <input v-model="artist.yod" class="shadow-none with-border" id="yod" type="text" autocomplete="off">
                </div>
              </div>
            </div>

            <div class="col-span-2">
              <label for="born_address" class="font-semibold">Quê quán</label>
              <input v-model="artist.born_address" class="shadow-none with-border" id="born_address" type="text"
                     autocomplete="off">
            </div>

            <div class="col-span-2">
              <label for="current_address" class="font-semibold">Chỗ ở hiện tại</label>
              <input v-model="artist.current_address" class="shadow-none with-border" id="current_address" type="text"
                     autocomplete="off">
            </div>

            <div class="col-span-2">
              <label for="work_address" class="font-semibold">Nơi công tác</label>
              <input v-model="artist.work_address" class="shadow-none with-border" id="work_address" type="text"
                     autocomplete="off">
            </div>

            <div class="col-span-2">
              <label for="phone" class="font-semibold">Số điện thoại</label>
              <input v-model="artist.phone" class="shadow-none with-border" id="phone" type="text"
                     autocomplete="off">
            </div>

            <div class="col-span-2">
              <label for="email" class="font-semibold">Email</label>
              <input v-model="artist.email" class="shadow-none with-border" id="email" type="text"
                     autocomplete="off">
            </div>
          </div>
        </div>
      </div>

      <div class="lg:w-1/4 w-full">
        <div class="mt-4">
          <label class="font-semibold">Ảnh đại diện</label>
          <DropZone type="image" :imageProp="image" v-model:objectIdProp="artist.image_id"/>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Phân loại</label>
          <select v-model="artist.type">
            <option value="domestic">Nghệ sĩ Việt Nam</option>
            <option value="foreign">Nghệ sĩ nước ngoài</option>
          </select>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Tài khoản liên kết</label>
          <multiselect
              v-model="owner"
              valueProp="id"
              label="username"
              trackBy="username"
              placeholder="Gõ để tìm kiếm"
              :closeOnSelect="true"
              :filterResults="false"
              :minChars="1"
              :resolveOnLoad="false"
              :delay="0"
              :searchable="true"
              :object="true"
              :options="searchUsers"
          >
            <template v-slot:option="{ option }">
              <img class="w-10 h-10 rounded-full object-cover mr-3"
                   :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                option.username
              }}
            </template>

            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                <img class="w-7 h-7 rounded-full object-cover mr-3"
                     :src="value.avatar ? value.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                  value.username
                }}
              </div>
            </template>
          </multiselect>
        </div>

        <div class="mt-7">
          <button type="button"
                  class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
                  v-on:click="createArtist">
            Thêm mới
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
input.multiselect-tags-search {
  height: auto !important;
}
</style>

<script>
import Multiselect from '@vueform/multiselect'
import DropZone from "@/components/DropZone.vue";
import Editor from "../../Editor";
import {canManagePeople, changePageTitle} from "../../../core/services/utils.service";
import ApiService from "../../../core/services/api.service";

export default {
  title: "AdminArtistEdit",
  components: {
    Editor,
    Multiselect,
    DropZone
  },
  data() {
    return {
      image: null,
      artist: {
        title: "",
        content: "",
        rank: "",
        real_name: "",
        dob: "",
        mob: "",
        yob: "",
        dod: "",
        mod: "",
        yod: "",
        born_address: "",
        current_address: "",
        work_address: "",
        phone: "",
        email: "",
        type: "domestic",
        image_id: null,
        user_id: null
      },
      owner: {
        id: null
      }
    }
  },
  methods: {
    createArtist() {
      if (!this.artist.title) {
        this.$toast.error("Bạn chưa nhập tên nghệ sĩ");
        return;
      }

      this.disabled = true;
      this.message = "";

      let query = `mutation($title: String!, $content: String, $rank: String, $real_name: String, $dob: String, $mob: String, $yob: String, $dod: String, $mod: String, $yod: String, $born_address: String, $current_address: String, $work_address: String, $phone: String, $email: String, $type: String, $image_id: ID, $user_id: ID) {
        createArtist(input: {
          title: $title,
          content: $content
          rank: $rank
          real_name: $real_name
          dob: $dob
          mob: $mob
          yob: $yob
          dod: $dod
          mod: $mod
          yod: $yod
          born_address: $born_address
          current_address: $current_address
          work_address: $work_address
          phone: $phone
          email: $email
          type: $type
          image_id: $image_id
          user_id: $user_id
        }) {
          id
        }
      }`;

      ApiService.graphql(query, {
        title: this.artist.title,
        content: this.artist.content,
        rank: this.artist.rank,
        real_name: this.artist.real_name,
        dob: this.artist.dob,
        mob: this.artist.mob,
        yob: this.artist.yob,
        dod: this.artist.dod,
        mod: this.artist.mod,
        yod: this.artist.yod,
        born_address: this.artist.born_address,
        current_address: this.artist.current_address,
        work_address: this.artist.work_address,
        phone: this.artist.phone,
        email: this.artist.email,
        type: this.artist.type,
        image_id: this.artist.image_id,
        user_id: this.owner ? this.owner.id : null
      })
          .then(({data}) => {
            if (data.data && data.data.createArtist) {
              this.$toast.success("Thêm nghệ sĩ thành công");
              this.$router.push({name: "AdminArtistList"});
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    async searchUsers(q) {
      let query = `query($q: Mixed) {
        users(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "username", operator: LIKE, value: $q}]}) {
          data {
            id
            username
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.users && data.data.users.data) {
              return data.data.users.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    }
  },
  computed: {
    canManagePeople() {
      return canManagePeople();
    }
  },
  mounted() {
    if (!canManagePeople()) {
      this.$router.push({name: "Home"});
    }

    changePageTitle("Thêm nghệ sĩ");
  }
}
</script>
